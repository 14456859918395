import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  status?: string
  title?: string
  description?: string
  button?: ButtonProps
}

export const Item = memo(function Item({
  image,
  status,
  title,
  description,
  button,
}: Props) {
  return (
    <Container>
      <Wrapper>
        <Left>
          <LazyLoadComponent threshold={150}>
            <StyledImage {...image} />
          </LazyLoadComponent>
        </Left>
        <Right>
          <Infos>
            {title ? (
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            ) : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {button ? <StyledButton {...button} /> : null}
          </Infos>
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.article`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
`

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`
const Left = styled.div`
  width: 50%;

  @media (max-width: 991px) {
    width: 100%;
  }
`
const StyledImage = styled(Image)`
  display: block;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
const Right = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
`
const Infos = styled.div`
  width: 100%;
  padding: 3vw 6vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`
const Title = styled.h3`
  font-size: 1.4vw;
  margin-bottom: 0.8vw;
  text-transform: uppercase;

  @media (max-width: 991px) {
    font-size: 18px;
    margin: 0 0 20px 0;
  }
`
const Description = styled.div``
const StyledButton = styled(Button)`
  width: 100%;
  max-width: none;
  margin-top: 1.5vw;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
`
