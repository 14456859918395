import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { DecropTop } from 'app/components/Common/Animation/Decrop'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { ScrollZoom } from 'app/components/Common/Animation/ScrollZoom'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Draw } from 'app/components/Common/Draw'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Lines } from 'app/components/Common/Lines'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  pretitle?: string
  title?: string
  description?: string
  button?: ButtonProps
  imageBig?: ImageProps
  imageSmall?: ImageProps
}

export const BlockHomeNaturalSpa = memo(function BlockHomeNaturalSpa({
  pretitle,
  title,
  description,
  button,
  imageBig,
  imageSmall,
}: Props) {
  return (
    <Container>
      <Lines />
      <StyledDraw variant="spa-01" />

      <Wrapper>
        <Left>
          <ImageBigWrapper>
            <LazyLoadComponent threshold={150}>
              <DecropTop>
                <ScrollZoom>
                  <Image {...imageBig} />
                </ScrollZoom>
              </DecropTop>
            </LazyLoadComponent>
          </ImageBigWrapper>

          <ImageSmallWrapper>
            <FadeIn>
              <LazyLoadComponent threshold={150}>
                <Image {...imageSmall} />
              </LazyLoadComponent>
            </FadeIn>
          </ImageSmallWrapper>
        </Left>

        <Right>
          {pretitle ? (
            <FadeInUp>
              <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
          <Text>
            {description ? (
              <DescriptionWrapper>
                <FadeInUp>
                  <Description
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </FadeInUp>
              </DescriptionWrapper>
            ) : null}
            {button ? (
              <FadeInUp>
                <Button {...button} />
              </FadeInUp>
            ) : null}
          </Text>
        </Right>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.6vw;
  position: relative;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`
const StyledDraw = styled(Draw)`
  left: 85%;
  top: 35%;
  transform: translate3d(-50%, -50%, 0);

  img {
    width: 15vw;
  }
`

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 991px) {
    display: block;
  }
`

const Right = styled.div`
  width: 50%;
  padding-left: 9vw;
  padding-top: 8vw;
  display: flex;
  flex-flow: column;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  flex: 0 1 auto;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 3vw 0;
  flex: 0 1 auto;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const Text = styled.div`
  padding: 0 2.7vw;
  padding-right: 8vw;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;

  &::before {
    top: 0;
    left: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    display: block;
  }

  @media (max-width: 991px) {
    padding: 0 30px;
  }
`

const DescriptionWrapper = styled.div`
  margin-bottom: 4vw;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }
`
const Description = styled.div`
  p {
    font-size: 21px;
    line-height: 32px;
  }
`

const Left = styled.div`
  width: 50%;
  position: relative;
  padding-right: 8vw;

  @media (max-width: 991px) {
    width: 100%;
    display: block;
    padding: 0;
  }
`
const ImageBigWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`

const ImageSmallWrapper = styled.div`
  top: 7vw;
  right: 9vw;
  position: absolute;
  width: 20vw;
  transform: translate3d(50%, 0, 0);
  border: 1vw solid ${({ theme }) => theme.colors.variants.neutralLight3};
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 991px) {
    display: none;
  }
`
