import styled from '@emotion/styled'
import { ArticlesList } from 'app/components/ArticlesList'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Lines } from 'app/components/Common/Lines'
import React, { memo } from 'react'

export interface Props {
  pretitle?: string
  title?: string
  button?: ButtonProps
  articles?: any
  languageCode: string
}

export const BlockHomeMagazine = memo(function BlockHomeMagazine({
  pretitle,
  title,
  button,
  articles,
  languageCode,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <Lines />
      <Wrapper>
        <Top>
          {pretitle ? (
            <FadeInUp>
              <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
          {button ? (
            <FadeInUp>
              <DesktopButton {...button} />
            </FadeInUp>
          ) : null}
        </Top>
        {articles ? (
          <ArticlesList articles={articles} languageCode={languageCode} />
        ) : null}
        {button ? (
          <FadeInUp>
            <MobileButton {...button} />
          </FadeInUp>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding: 6.6vw;
  position: relative;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Wrapper = styled.div``

const Top = styled.div`
  position: relative;
`

const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 3vw 0 0 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const DesktopButton = styled(Button)`
  position: absolute;
  bottom: 0.5vw;
  right: 0;

  @media (max-width: 991px) {
    position: static;
    margin: 20px 0;
    display: none;
  }
`
const MobileButton = styled(Button)`
  display: none;

  @media (max-width: 991px) {
    margin-top: 20px;
    display: block;
  }
`
