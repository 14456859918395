import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Arrow } from 'app/components/Common/Arrow'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Item, Props as ItemProps } from './item'

export interface Props {
  pretitle?: string
  title?: string
  items?: ItemProps[]
}

export const BlockHomeNews = memo(function BlockHomeNews({
  pretitle,
  title,
  items,
}: Props) {
  const [_currentSlide, setCurrentSlide] = useState(0)
  const [sliderMain, instanceRefMain] = useKeenSlider({
    loop: true,
    initial: 0,
    slides: { perView: 1, spacing: 0 },
    defaultAnimation: {
      duration: 1500,
    },
    breakpoints: {
      '(max-width: 991px)': {
        slides: { perView: 1.2, spacing: 5, origin: 'center' },
        loop: false,
        drag: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
      let index = s.track.details.rel
    },
  })

  return (
    <>
      {items && items?.length > 0 ? (
        <Container>
          <FadeInUp>
            <WrapperTop>
              {pretitle ? (
                <FadeInUp>
                  <Pretitle dangerouslySetInnerHTML={{ __html: pretitle }} />
                </FadeInUp>
              ) : null}
              <Title text={title} />
            </WrapperTop>
          </FadeInUp>
          <FadeInUp>
            <SliderWrapper>
              <Slider ref={sliderMain} className="keen-slider">
                {items?.map((item, index) => (
                  <Slide key={index} className="keen-slider__slide">
                    <Item {...item} />
                  </Slide>
                ))}
              </Slider>
              {items && items?.length > 1 ? (
                <>
                  <Arrow
                    className="arrow-left"
                    onClick={(e) => {
                      instanceRefMain.current?.prev()
                    }}
                  />

                  <Arrow
                    className="arrow-right"
                    direction="R"
                    onClick={(e) => {
                      instanceRefMain.current?.next()
                    }}
                  />
                </>
              ) : null}
            </SliderWrapper>
          </FadeInUp>
        </Container>
      ) : null}
    </>
  )
})

const Container = styled.section`
  padding: 6.6vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const WrapperTop = styled.div`
  text-align: center;
`
const Pretitle = styled.h3`
  font-size: 0.9vw;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
const Title = styled(AnimatedTitle)`
  font-size: 3vw;
  margin: 2vw 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 28px;
    margin: 20px 0;
  }
`

const SliderWrapper = styled.div`
  position: relative;
  margin-top: 4vw;

  .arrow-left {
    top: 50%;
    left: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-right {
    top: 50%;
    right: 0;
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate3d(50%, -50%, 0);
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    width: calc(100% + 65px);
    margin-left: -35px;

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
`
const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`
const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`
