import styled from '@emotion/styled'
import { BlockHomeDeals } from 'app/components/BlockHomeDeals'
import { BlockHomeFood } from 'app/components/BlockHomeFood'
import { BlockHomeMagazine } from 'app/components/BlockHomeMagazine'
import { BlockHomeNaturalSpa } from 'app/components/BlockHomeNaturalSpa'
import { BlockHomeNews } from 'app/components/BlockHomeNews'
import { GalleryAccomodations } from 'app/components/GalleryAccomodations'
import { GalleryDouble } from 'app/components/GalleryDouble'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { Mosaic } from 'app/components/Mosaic'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}

      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          flashNewsProps={
            context.flashNewsProps ? context.flashNewsProps : null
          }
          {...context.headerProps}
        />
      ) : null}

      {context.heroProps ? (
        <Hero
          noTitle={true}
          bookingEngineData={
            context.bookingEngineDataProps
              ? context.bookingEngineDataProps
              : null
          }
          {...context.heroProps}
        />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.galleryDoubleProps ? (
        <GalleryDouble {...context.galleryDoubleProps} />
      ) : null}
      {context.galleryAccomodationsProps ? (
        <GalleryAccomodations {...context.galleryAccomodationsProps} />
      ) : null}
      {context.blockHomeFoodProps ? (
        <BlockHomeFood {...context.blockHomeFoodProps} />
      ) : null}
      {context.blockHomeNaturalSpaProps ? (
        <BlockHomeNaturalSpa {...context.blockHomeNaturalSpaProps} />
      ) : null}
      {context.blockHomeNewsProps ? (
        <BlockHomeNews {...context.blockHomeNewsProps} />
      ) : null}
      {context.blockHomeDealsProps ? (
        <BlockHomeDeals {...context.blockHomeDealsProps} />
      ) : null}
      {context.blockHomeMagazineProps ? (
        <BlockHomeMagazine {...context.blockHomeMagazineProps} />
      ) : null}
      {context.mosaicProps ? (
        <Mosaic noGallery={true} {...context.mosaicProps} />
      ) : null}

      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
